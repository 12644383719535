<template>
  <div class="HomeAndroid" id="HomeAndroid">
    <!-- 头部导航 -->
    <div style="position: fixed; left: 0; right: 0; top: 0; z-index: 100">
      <div class="topbar">
        <div class="t-left">
          <img  @click="showMenu()" style="width:.8rem;margin-right: .45rem;" :src='require("@/assets/imgs/menu.png")' /> 
          <img :src="G18logoImg" alt="" style="height:.8rem;"/>
        </div>
        <ChangeLanguage />
        <!-- <div class="t-right" @click="objData.collect = true">
          {{ $t("收藏我們") }}
        </div> -->
      </div>
    </div>

    <!-- 中间内容部分 -->
    <div class="content" @click="showMenu1()">
      <!-- 轮播图部分 -->
      <div class="content1">
        <v-carousel cycle interval="3000" v-model="model" height="100%">
          <v-carousel-item v-for="(item, index) in bannerList" :key="index">
            <v-img
            style="box-shadow: 0px 8px 20px #D1E1EA;border-radius: 10px;"
              :src="item.url_mobile"
              :lazy-src="item.url_mobile"
              alt=""
              @click="
                gotoGame(item.url, item.url_type, item.banner_type, item.id)
              "
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-carousel-item>
        </v-carousel>
      </div>
      <!-- 加赠图片 -->
      <!-- <div class="folatImg" v-if="userToken" @click="getfloatImgClick()">
        <img src="../assets/imgs/folat-img.gif" alt="" />
      </div> -->
      <!-- 站长推荐 -->
      <div class="content2" id="content2">
        <div class="recommend">{{ $t("站長推薦") }}</div>
        <p v-show="isloading">{{ $t("加載中") }}</p>
        <ul class="ma-0 pa-0" v-if="recommendList">
          <li
            v-for="(item, index) in recommendList"
            :key="index"

          >
          <v-img v-if="item.hot"  :src="require('@/assets/imgs/fire.png')" class="fire"></v-img>
            <div class="gameCover"> <v-img
                 height="2.4rem"
                class="rounded-lg"
                :src="BigImg(item.cover)"
                :lazy-src="item.cover"
                alt=""
                ><template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <div class="name">{{item.name}}</div>
            </div>
            <div class="gameImg" >
              <v-img
                class="rounded-lg"
                :src="BigImg(item.icon)"
                :lazy-src="item.icon"
                alt=""
                ><template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </div>
            <div class="download" 
            @click="toGameDetails(item)">{{ $t("下載") }}</div>
          </li>
        </ul>
        <p v-else>{{ $t("暫無數據") }}</p>
      </div>
      <!-- 即將推出 -->
      <div class="content3" id="content3">
        <div class="news">{{ $t("即將推出") }}</div>
        <p v-show="isloading">{{ $t("加載中") }}</p>
        <ul class="ma-0 pa-0" v-if="newsList" >
          <!-- @click="downloadGame(item)" -->
          <li v-for="(item, index) in newsList" :key="index">
            <div class="gameImg">
              <v-img
                class="rounded-lg"
                :src="BigImg(item.icon)"
                :lazy-src="item.icon"
                alt=""
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </div>
            <div class="gameName">{{ item.name }}</div>
            <div class="download">
              <div class="btn" :style="`${item.booked?'background:#00C7DB':''}`" @click="booking(item)">{{ item.booked?$t("已預約"): $t("預約") }}</div>
            </div> 
          </li>
        </ul>
        <p v-else>{{ $t("暫無數據") }}</p>
      </div>
    </div>
    <!-- 底部栏 -->
    <div class="botbar">
      <p>
        {{ $t("本網站涉及成人內容,未滿18歲的人不得瀏覽,否則您將願意接受懲罰") }}
      </p>
      <p>
        <span class="sp1" @click="$router.push('/TOS')">
          {{ $t("服務條款") }}</span
        >
        <span class="sp2"></span>
        <span class="sp3" @click="$router.push('/Privacy')">
          {{ $t("隱私權條款") }}</span
        >
      </p>
    </div>
    <!-- 左边菜单栏 -->
    <LeftMenu :objData="objData" :userToken="userToken" ref="LeftMenu" />
    <!-- 收藏页 -->
    <div class="Collect" v-if="objData.collect" v-fixed>
      <div class="icon" @click="objData.collect = false">
        <v-icon color="#fff" size="40">mdi-close</v-icon>
      </div>
      <div class="qrcode">
        <div class="code-img">
          <vue-qr :text="text ? text : 'none'" height="85%" :margin="0" />
        </div>
      </div>
      <div class="message">
        <h1 class="pa-0 ma-0">G18</h1>
        <p>{{ $t("趕快截圖收藏永久鏈接才不會找不到我們") }}</p>
      </div>
      <div class="Url">
        <p class="pa-0 ma-0">{{ $t("永久地址") }}</p>
        <p class="pa-0 ma-0">{{ protocol + "//" + host }}</p>
      </div>
    </div>
    <Dialog ref="download" />
    <modal />
  </div>
</template>

<script>
import modal from "@/components/18modal";
// 引入i18n
import i18n from "@/i18n/index";
import LeftMenu from "@/components/LeftMenu";
import VueQr from "vue-qr";
import SvgIcon from "@/components/Svgicon";
// 切换语言
import ChangeLanguage from "@/components/ChangeLanguage/index.vue";
export default {
  components: {
    modal,
    SvgIcon,
    VueQr,
    LeftMenu,
    ChangeLanguage,
  },
  data() {
    return {
      // logo图标
      G18logoImg: require("@/assets/imgs/logo-home.png"),
      userToken: "",
      isloading: true,
      protocol: null,
      host: null,
      text: "none",
      objData: {
        collect: false,
      },
      model: 0,
      // 控制左侧菜单弹出
      Showmenu: false,
      // 轮播图片
      bannerList: [],
      // 站长推荐
      recommendList: [],
      // 最新上架
      newsList: [],
    };
  },
  created() {
    var appToken = this.getQueryString("appToken");
    if (appToken) {
      this.$get("/api/account/getNewToken", {
        token: appToken,
        gameId: process.env.VUE_APP_API_BASE_GameId,
      }).then((res) => {
        localStorage.setItem("token", res.data.token);
      });
    }
    this.userToken = localStorage.getItem("token");
    this.protocol = window.location.protocol;
    this.host = window.location.host;
    this.text = this.protocol + "//" + this.host;
    this.getHomeData();
  },
  methods: {
    booking(item){
      this.$get("/api/Home/bookingGame", {
          gameId:item.id,
          booked:!item.booked
        }).then((res) => {
            item.booked=!item.booked
         });
    },
    getfloatImgClick() {
      this.$router.push("/Recharge");
      // 获取点击浏览记录
      this.getViewCount(3005);
    },
    getQueryString(name) {
      var value;
      var url = window.location.href;
      var pairs = url.split("?");
      if (pairs.length < 2) return value;
      pairs = pairs[pairs.length - 1].split("&");
      if (pairs.length < 1) return value;
      pairs.forEach((element) => {
        var pair = element.split("=");
        var key = pair[0];
        if (key.toLowerCase() == name.toLowerCase()) {
          value = pair[1];
          return;
        }
      });
      return value;
    },
    // 获取首页数据
    getHomeData() {
      this.$get("/api/Home/Data").then((res) => {
        this.bannerList = res.data.banners;
        this.recommendList = res.data.recommends;
        this.newsList = res.data.news;
        //this.recommendList=null;
        //this.newsList=null;
        this.isloading = false;
      });
    },
    gotoGame(url, url_type, banner_type, banner_id) {
      if (!url) return;
      if (url_type) {
        this.getViewCount(3004, banner_id);
        this.$router.push(url);
      } else {
        if (banner_type == 1) {
          this.$refs.download.dialog2 = true;
          this.$refs.download.text1 = "温馨提示";
          this.$refs.download.text2 = "請下載該遊戲、於遊戲內兌換！";
          this.getViewCount(3004, banner_id);
          return;
        }
        this.getViewCount(3004, banner_id);
        window.open(url);
      }
    },
    BigImg(item) {
      if(!item) return null;
      if (item.indexOf("-big") > 0) {
        this.bigimg = item;
      } else {
        var hz = item.slice(item.lastIndexOf("."));
        var img = item.substring(0, item.lastIndexOf("."));
        this.bigimg = img + "-big" + hz;
      }
      return this.bigimg;
    },
    showMenu() {
        this.$refs.LeftMenu.showMenu();
    },
    showMenu1() {
      if(this.$refs.LeftMenu)
      this.$refs.LeftMenu.closeMenu();
    },
    toGameDetails(game) {
      this.$router.push({ path: "/GameDetails", query: { id: game.id } });
    },
    // 跳转网址
    downloadGame(item) {
      this.getViewCount(3001, item.id);
      window.open(`${item.landing_page}?${item.id}`);
      // if (isAndroid) {
      //   window.open(item.download_android);
      //   return;
      // }
      // if (isIOS) {
      //   window.open(item.download_ios);
      //   return;
      // }
      // window.open(item.download_windows);
    },
    // 获取点击浏览记录
    getViewCount(code, id) {
      this.$post("/api/Home/G18ClickDown", {
        code: code,
        game_id: id,
      }).then((res) => {
        console.log(res);
      });
    },
  },
  directives: {
    fixed: {
      // inserted 被绑定元素插入父节点时调用
      inserted() {
        let scrollTop =
          document.body.scrollTop || document.documentElement.scrollTop;
        document.body.style.cssText +=
          "position:fixed;width:100%;top:-" + scrollTop + "px;";
      },
      // unbind 指令与元素解绑时调用
      unbind() {
        let body = document.body;
        body.style.position = "";
        let top = body.style.top;
        document.body.scrollTop = document.documentElement.scrollTop =
          -parseInt(top);
        body.style.top = "";
      },
    },
  },
  watch: {
    locale(val) {
      console.log();
      this.$i18n.locale = val;
      localStorage.setItem("locale", val);
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.HomeAndroid {
  padding: 1.6rem 0.64rem 1.06667rem;
    background: #F0F6F9;
    padding-bottom: 2.5rem;
  .topbar {
    height: 1.45rem;
    display: flex;
    background: #F0F6F9;
    align-items: center;
    padding: 18px 24px;
    justify-content: space-between;
    .t-left {
      display: flex;
      align-items: center;
      font-size: 24px;
      color: #fff;
      font-weight: 400;
      .icon {
     
        margin-right: 10px;
      }
      .logoImg {
        margin-top: 3px;
        img {
          display: block;
          width: 60px;
        }
      }
    }
    .t-right {
      font-size: 14px;
      padding: 3px 10px;
      border-radius: 20px;
      border: 1px solid #fffdfd;
      color: #fffdfd;
    }
  }
  .content {
    overflow: auto;
    width: 100%;
    .content1 {
      height: 123px;
      .v-window::v-deep {
        .v-carousel__controls {
          background: none;
          height: 35px !important;
          .v-btn {
            height: 8px !important;
            width: 8px !important;
            .v-btn__content {
              ::before {
                border-radius: 50%;
                font-size: 5px;
                background-color: #757575;
              }
            }
          }
        }
        .v-item--active {
          background: #fff;
          height: 8px;
          width: 8px;
        }
        .v-window__prev,
        .v-window__next {
          display: none;
        }
        .v-responsive__content {
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
          img {
            width: 100%;
            height: 135px;
            display: block;
          }
        }
      }
    }
    .folatImg {
      margin-top: 15px;
      img {
        width: 100%;
        display: block;
      }
    }
    .content2 {
      p {
        font-size: 14px;
      }
      .recommend {
        font-size: .45rem;
    text-align: left;
    color: #333333;
    padding: 0.26667rem 0;
    font-weight: 500;
    font-family: 'PingFang SC';
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          position: relative;
    padding: 0.16rem;
    width: 50%;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.53333rem;
    background: linear-gradient(118.03deg, #D8D7F2 24.9%, #FCE5EC 95.48%);
    border: 0.01333rem solid #FFFFFF;
    box-shadow: 0 0.21333rem 0.26667rem rgb(159 184 197 / 20%);
    border-radius: 0.26667rem;
    
    .fire{
      width: 0.7rem;
    right: 0;
    position: absolute;
    z-index: 2;
    top: -0.35rem;
    }
    .gameCover{
      width: 100%;
    position: relative;
    height: 2.8rem;
    .name{
      background: rgba(0, 0, 0, 0.5);
    font-size: 0.3rem;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: right;
    padding-right: 0.3rem;
    line-height: 0.58rem;
    }
    }
          .gameImg {
            width: 1.3rem;
    height: 1.3rem;
    margin-top: -0.6rem;
    border: 0.01333rem solid #FFFFFF;
    box-shadow: 0 0.10667rem 0.16rem rgb(214 166 184 / 50%);
    border-radius: 0.26667rem;

            img {
              width: 100%;
              display: block;
            }
          }
          .gameName {
            margin: 10px 0;
            font-size: 13px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            line-height: 1.5;
            -webkit-box-orient: vertical;
          }
          .download {
            background: linear-gradient(91.47deg, #ff5b8c -39.27%, #fd84ad 120.35%);
    border-radius: 0.18rem;
    font-size: 0.3rem;
    color: #fff;
    line-height: 0.55rem;
    text-align: center;
    position: absolute;
    bottom: 0.05rem;
    right: 0.16rem;
    padding: 0 0.3rem;
          }
        }
      }
    }
    .content3 {
      p {
        font-size: 14px;
      }
      .news {
        font-size: .45rem;
    text-align: left;
    color: #333333;
    padding: 0.26667rem 0;
    margin-bottom: .4rem;
    font-weight: 500;
    font-family: 'PingFang SC';
      }
      ul {
        display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
        li {
          width: 30%;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 20px;
          background: linear-gradient(118.03deg, #D8D7F2 24.9%, #FCE5EC 95.48%);
border: 0.5px solid #FFFFFF;
box-shadow: 0px 8px 10px rgba(159, 184, 197, 0.2);
border-radius: 10px;
          .gameImg {
            width: 1.4rem;
    height: 1.4rem;
    margin-top: -0.3rem;
    border: 0.01333rem solid #FFFFFF;
    box-shadow: 0 0.10667rem 0.16rem rgb(214 166 184 / 50%);
    border-radius: 0.26667rem;
            img {
              
            }
          }
          .gameName {
            margin:.1rem 0;
            font-size: 0.3rem;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            line-height: 1.5;
            -webkit-box-orient: vertical;
        color: #485573;

          }
          .download {
            width: 100%;
    height: 0.9rem;
    background: rgba(157, 155, 192, 0.2);
    border-radius: 0 0 0.26667rem 0.26667rem;
    font-size: 0.3rem;
    color: #fff;
    line-height: 0.8rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn{
      background: linear-gradient(91.47deg, #ff5b8c -39.27%, #fd84ad 120.35%);
    border-radius: 0.18rem;
    font-size: 0.3rem;
    color: #fff;
    line-height: 0.55rem;
    padding: 0 0.3rem;
    }

          }
        }
      }
    }
  }
  .botbar {
    z-index: 100;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #797979;
    padding: 11px 58px;
    position: fixed;
    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      margin-bottom: 5px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .sp1,
    .sp3 {
      color: #cdcdcd;
      margin: 0 10px;
      cursor: pointer;
    }
    .sp2 {
      display: inline-block;
      height: 10px;
      width: 2px;
      background-color: #cdcdcd;
    }
  }
  .Collect {
    height: 100vh;
    background: url("~@/assets/imgs/Collet-bgc1.jpg") fixed no-repeat;
    background-size: 100%;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .icon {
      position: absolute;
      top: 3%;
      left: 5%;
    }
    .qrcode {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 25%;
      width: 220px;
      height: 220px;
      background-color: #fff;
      border-radius: 20px;
      .code-img {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          display: block;
        }
      }
    }
    .message {
      width: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 60%;
      text-align: center;
      h1 {
        font-size: 32px;
        font-weight: 700;
        color: #000;
      }
      p {
        font-size: 18px;
        font-weight: 600;
        padding: 0 50px;
        color: #000;
      }
    }
    .Url {
      width: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 15%;
      text-align: center;
      p {
        font-size: 20px;
        color: #919191;
      }
    }
  }
}
</style>